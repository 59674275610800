import React from "react";
import { Spin, Space } from "antd";

export default function Loader({ message }) {
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ width: "100%", height: "100vh" }}
    >
      <Spin size="large" />
      {message && (
        <p
          style={{
            width: "100%",
            maxWidth: "500px",
            textAlign: "center",
            marginTop: "16px",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
}
