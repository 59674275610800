import React from 'react'
import style from '../style/login.module.css'

const CompanCard = ({ affiliateText, affiliateSubtext, affiliateImage }) => {
  return (
    <div className={style.companCard} style={{ padding: '1em' }}>
      <div className={style.topSection}>
        <h1>
          <img src='/logo.png' style={{ width: '180px' }} />
        </h1>
        {affiliateSubtext ? <p>{affiliateSubtext}</p> : ''}
      </div>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div className={style.badgeContainer}>
          <div>
            <img src='/icons/increaseSales.svg' alt='' />
          </div>
          <div>
            <h3>Increase sales</h3>
            <p>Showcase your products in 50,000 monthly searches</p>
          </div>
        </div>
        <div className={style.badgeContainer}>
          <div>
            <img src='/icons/commisionFree.svg' alt='' />
          </div>
          <div>
            <h3>Commission free</h3>
            <p>We bring you more sales without taking a margin</p>
          </div>
        </div>
        <div className={style.badgeContainer}>
          <div>
            <img src='/icons/hassleFree.svg' alt='' />
          </div>
          <div>
            <h3>Hassle free</h3>
            <p>Automatic upload and refresh of your product inventory</p>
          </div>
        </div>
      </div>

      <h2 style={{ fontWeight: '500' }}>
        "Since becoming an Agora merchant, Calzuro has seen increased
        visibility, traffic, and sales, and we look forward to future success on
        the platform!"
      </h2>

      <div className={`d-flex justify-content-center align-items-center mt-4`}>
        <img
          src='/images/calzuroImage.jpeg'
          style={{ width: 80, height: 80 }}
          alt=''
        />
        <div className='mx-3'>
          <p style={{ fontWeight: 'bold' }} className='mb-1'>
            Brooke H.
          </p>
          <p className='mb-1'>Marketing, Calzuro</p>
        </div>
        <img
          src='/images/calzuroLogo.png'
          style={{ maxHeight: '3rem' }}
          alt=''
        />
      </div>
    </div>
  )
}

export default CompanCard
