import React, { useEffect, useState } from 'react'
import { Input, Button, Form } from 'antd'
import style from '../style/login.module.css'
import signUpStyle from '../style/signup.module.css'
import CompanCard from '../components/CompanCard'
import { Row, Col } from 'react-bootstrap'
import { login, verifyAccount, loadUser } from '../redux/action/authActions'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Spin } from 'antd'
import MetaTags from '../components/MetaTags'
import Loader from '../components/Loader'

export async function getServerSideProps(context) {
  const { otp } = context.query
  return {
    props: { otp: otp || null },
  }
}
// trigger
const Login = ({ otp }) => {
  const dispatch = useDispatch()
  const { isAuthenticated, loading } = useSelector((state) => state.auth)
  const user = useSelector((state) => state.auth.user)
  const [loader, setLoading] = useState(false)
  const [verifyLoader, setVerifyLoader] = useState(false)
  const router = useRouter()

  useEffect(() => {
    window.scrollTo(0, 0)

    if (router.query.otp) {
      dispatch(verifyAccount(router.query.otp))
    }
  }, [router.query])

  useEffect(() => {
    dispatch(loadUser())

    document.querySelector('body').style.backgroundColor = '#f1f1f1'

    return () => {
      document.querySelector('body').style.backgroundColor = 'white'
    }
  }, [])

  useEffect(() => {
    if (router.query.otp) {
      setVerifyLoader(true)
    } else {
      setVerifyLoader(false)
    }
  }, [router])

  // useEffect(() => {
  //   setVerifyLoader(loading);
  // }, [loading]);

  const handleFinish = async (value) => {
    setLoading(true)
    await dispatch(login({ ...value, type: 'merchant' }))
    setLoading(false)
  }

  if (isAuthenticated && user && user.type !== 'visitor') {
    router.push('/merchantHome')
  }
  return verifyLoader || loading || (isAuthenticated && user) ? (
    <Loader
      message={
        verifyLoader &&
        'Your email has been confirmed! We are configuring your Agora dashboard. This process can take up to 2 minutes.'
      }
    />
  ) : (
    <div className={style.login}>
      <MetaTags
        title={'Agora Merchant Log In'}
        description={
          'Search across the e-commerce web to find, save, share and purchase your favorite online products. All in one place.'
        }
      />
      <Row>
        <Col md={4}>
          <div className={signUpStyle.companSideBar}>
            <CompanCard />
          </div>
        </Col>
        <Col
          className='d-flex justify-content-center align-items-center'
          md={8}
        >
          <div className={style.loginForm}>
            <h3>Log in</h3>
            <Form onFinish={handleFinish} className={style.form}>
              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    message: `'Email' is required`,
                  },
                ]}
              >
                <Input
                  className={style.formInput}
                  type='email'
                  placeholder='Email'
                />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: `'Password' is required`,
                  },
                ]}
              >
                <Input.Password
                  className={style.formInput}
                  type='password'
                  placeholder='Password'
                />
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={loader}
                  htmlType='submit'
                  className={style.loginBtn}
                >
                  {loader ? <Spin /> : <>Log in</>}
                </Button>
              </Form.Item>

              <Form.Item>
                <Link href='/signup' className={style.signupBtn}>
                  Don't have an account?
                </Link>
              </Form.Item>

              <Link href='/forgot' className={style.signupBtn}>
                Forgot password?
              </Link>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Login
